/* Global Styles */
html {
  background-color: #fff;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

input {
  margin-bottom: 20px;
}

/* Body Content Styles */
.content-body-container {
  min-width: 1100px;
  max-width: 1100px;
  margin: auto;
  position: relative;
}

/* Long Body Content Styles */
.content-body-container-long {
  margin: 0px 30px;
}

/* Navigation Styles */
.submenu-container .ui.placeholder.segment {
  min-height: 7em;
  background: #f7f8fb;
  padding-top: 22px;
  border-radius: 0px;
}

.submenu-container button {
  float: right !important;
  position: absolute !important;
  right: 6px !important;
  top: 15px !important;
}

.submenu-container .ui.placeholder .header {
  margin-bottom: 6px;
}

.submenu-container p {
  font-weight: 800;
  opacity: 0.65;
}

/* Margins */
.margin-top-zero {
  margin-top: 0px !important;
}

.margin-bottom-zero {
  margin-top: 0px !important;
}

/* Menu Styles */
.portal-container .ui.menu {
  margin-bottom: 0px;
  border-radius: 0px;
}

.header-logo {
  height: 23px;
  position: absolute;
  right: 0px;
  left: 0px;
  margin: auto;
  top: 18px;
  width: 114px;
}

/* Misc Styles */
.copyright-tag {
  bottom: 14px;
  left: 0px;
  right: 0px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px !important;
  text-align: center;
  color: #8c8c8c;
}

/* Authentication Styles */
.authentication-global-wrapper {
  background: #f7f8fb;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 150px;
}

.authentication-global-wrapper .truck-icon {
  width: 70px;
  opacity: 0.8;
}

.logo {
  width: 180px;
  margin-bottom: 20px;
}

.authentication-global-wrapper .ui.card {
  width: 350px;
}

.authentication-global-wrapper .ui.card>.content {
  padding: 2em 1.5em;
}

/* Table Styles */
.table-container {
  margin-top: 15px !important;
  padding: 0px !important;
}

.table-container .ReactTable .rt-thead.-header {
  background: #f7f8fb;
  box-shadow: none;
  border-bottom: 1px solid #d9d9d9;
}

.table-container .ReactTable .rt-thead .rt-resizable-header-content {
  font-weight: 700;
  text-align: left;
  font-size: 16px;
}

.table-container .ReactTable .rt-thead .rt-th.-cursor-pointer,
.table-container .ReactTable .rt-thead .rt-td.-cursor-pointer {}

.table-container .ReactTable .rt-thead .rt-th,
.table-container .ReactTable .rt-thead .rt-td {
  background: #f7f8fb;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.table-container .ReactTable.-striped .rt-tr.-odd {
  background: #f7f8fb;
}

.table-container .ReactTable .rt-tbody .rt-td {
  font-weight: 600;
}

.table-container .address-line-cell {
  text-align: center;
}

.table-container .multi-line-cell p {
  margin-bottom: 2px;
}

.table-container .ReactTable .rt-th,
.table-container .ReactTable .rt-td {
  flex: 100 0 auto;
  width: 100px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
}

.table-container .ReactTable .-pagination {
  box-shadow: none;
}

/* Table Actions */
.table-actions-row {
  margin-top: 15px !important;
  margin-bottom: 21px !important;
  height: 30px;
}

.float-right {
  float: right;
}

.width300 .ui.input {
  width: 260px;
}

/* Pick Pack */
.pick-pack-container {
  background: white;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.pick-pack-container .pick-pack-menu {
  background: #edeef0;
  padding: 20px;
}

.pick-pack-container .pick-pack-menu .menu-header {
  float: left;
  margin-right: 10px;
}

.pick-pack-container .pick-pack-content {
  padding: 18px 20px;
}

.pick-pack-container .ui.steps .step.active .title {
  color: black !important;
}

.pick-pack-container .item-display-container {
  margin-top: 16px;
}

.pick-pack-container .ui.segments:last-child {
  margin-top: 20px;
}

.pick-pack-container .item-display-container .quantity-label {
  position: absolute;
  right: 25px;
  top: 27px;
}

.pick-pack-container .ui.steps .link.step,
.pick-pack-container .ui.steps a.step {
  padding: 26px;
}

.pick-pack-container .ui.piled.segment,
.pick-pack-container .ui.piled.segments {
  margin: 0px;
  margin-bottom: 10px;
}

.pick-pack-container .ui.massive.segment,
.pick-pack-container .ui.massive.segments .segment {
  padding: 20px;
  font-weight: 800;
}

.pick-pack-container h3.ui.header {
  margin: 0px;
  margin-left: 66px;
  width: 450px;
  margin-top: 10px;
  opacity: 0.8;
  font-weight: 500;
}

.shipment-dock h3.ui.header {
  margin-left: 0px;
}
.ui.modal>.close {
  font-size: 40px;
}
.shipment-dock .ui.divided.list>.item {
  padding: 10px 2px;
}

.pick-pack-container .cart-item-image {
  float: left;
  width: 60px;
  margin-right: 10px;
  cursor: pointer;
}

.pick-pack-container .ui.ordered.steps .step.completed:before,
.pick-pack-container .ui.steps .step.completed>.icon:before {
  font-size: 25px;
  color: #21ba45;
}

.pick-pack-container .ui.card>.content p,
.pick-pack-container .ui.cards>.card>.content p {
  font-size: 1.3em;
}

.pick-pack-container .pick-pack-dock-footer {
  bottom: 16px;
  left: 0px;
  right: 0px;
  margin: auto;
}

/* Order Counter */
.order-counter-container {
  position: fixed;
  width: 160px;
  bottom: -80px;
  left: 120px;
  font-size: 10px;
  text-align: center;
  padding: 10px;
  background: white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0px 0px 4px #c4c4c4;
  border-radius: 4px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.opened {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  bottom: 0px;
}

.order-counter-container p {
  font-size: 18px;
}

/* Projection Table Stylsing */
.long-table {
  overflow-x: scroll;
}

.long-table .border-top {
  border-top: 2px solid grey !important;
}

.long-table .ui.table {
  table-layout: fixed;
  width: 8000px;
}

.long-table .ui.table td input {
  width: 100%;
  height: 100%;
  text-align: right;
}

/* Dashboard Styles */
.dashboard-container .ui.statistics {
  margin: 0px;
}

.dashboard-container .ui.statistics>.statistic {
  border: 1px solid #dbdcdf;
  margin: 7px;
  padding: 18px 7px;
  box-shadow: 0px 3px 3px #80808024;
  border-radius: 3px;
  width: 206px;
  margin-left: 0px;
  margin-right: 13px;
}

.dashboard-container .ui.statistic>.value~.label,
.ui.statistics .statistic>.value~.label {
  color: black;
  opacity: .8;
  text-transform: capitalize;
}

.dashboard-container .ui.statistic>.value,
.dashboard-container .ui.statistics .statistic>.value {
  font-weight: 800;
}

.ui.huge.statistic>.value, .ui.huge.statistics .statistic>.value {
  font-size: 5em !important;
}

.dashboard-container .chart-row-container {
  margin-top: 16px;
}

.dashboard-container .table-actions-row {
  min-height: 30px;
}

.table-actions-row .show-orders-description {
  display: inline;
  text-align: right;
  margin-right: 15px;
  font-weight: 800;
  font-size: 16px;
  line-height: 34px;
}
.table-actions-row .message-indicator {
  float: left;
}
.table-actions-row .message-indicator .message {
  height: 100px !important;
  max-width: 430px;
}
/* Toast Styles */
.Toastify__toast-body {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* Keyboard Help */
.keyboard-help-icon {
  position: fixed;
  left: 20px;
  bottom: 20px;
}

.code {
  font-family: monospace;
  color: red;
  background: white;
  padding: 2px 4px;
  border-radius: 3px;
}

/* .dimmed.dimmable>.ui.animating.dimmer, .dimmed.dimmable>.ui.visible.dimmer, .ui.active.dimmer {
  cursor: pointer;
} */
.ui.tiny.modal {
  width: fit-content !important;
  border-radius: 13px;
}
.ui.modal>.close {
  font-size: 30px;
  margin-left: 30px;
}
.number-button-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.ui.modal>.header:not(.ui) {
  text-align: center;
}
.number-input {
  outline: none;
  width: 275px;
  text-align: center;
  border: none;
  line-height: 40px;
  font-size: 50px;
  /* letter-spacing: 25px; */
  background: #f4f4f4;
  border-radius: 5px;
  
}

.backspace-button {
  font-size: 15px !important;
}
.align-right {
  justify-content: flex-end;
  /* align-items: flex-end; */
}

.quick-switch-modal-content .ui.negative.message {
  max-width: 272px !important;
}

/* Mobile Styles */
@media only screen and (max-width: 1168px) {

  /* Authenitcation Mobile Styles */
  .content-body-container {
    min-width: 950px;
    max-width: 950px;
  }
}

@media only screen and (max-width: 768px) {

  /* Authenitcation Mobile Styles */
  .authentication-global-wrapper {
    padding: 30px 15px;
  }
}

/* Print Pick Slip Styules */
.print-pick-slip-container {
    padding: 50px 80px;
    max-width: 1300px;
    margin: auto;
}
.print-pick-slip-container p {
  font-family: monospace !important;
}

/* Single Slip Styles */
.single-slip-container {
  height: 100vh;
  padding: 40px 30px;
  position: relative;
  border: 1px solid black;
}

.order-information-container {
  float: left;
}

.order-information-container p {
  font-size: 16px;
  line-height: 15px;
}

.bar-code-container svg {
    max-width: 400px;
    float: right;
}

.product-container {
  border: 2px solid black;
  margin-top: 27vh;
  padding: 20px;
  min-height: 62vh;
}

.pick-slip-row {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 0px;
  text-align: left;
  border-bottom: 1px solid black;

}

.pick-slip-row-item {
  text-align: left;
  
}

.pick-slip-row-item img {
  max-width: 50px;
}

.pick-slip-row-item p {
  text-align: left !important;
  font-size: 19px;

}

.pick-slip-row-item-small {
  text-align: left;
  
}

.pick-slip-row-item-small img {
  max-width: 20px;
}

.pick-slip-row-item-small p {
  text-align: left !important;
  font-size: 12px !important;

}
.pick-slip-row-item-small .pick-slip-row-item p {
  text-align: left !important;
  font-size: 12px !important;

}

.pick-slip-row .print-image {
  margin-right: 20px;
  flex: .2;
}

.pick-slip-row .print-location {
  margin-right: 35px;
  flex: 1.25;
}

.pick-slip-row .print-sku {
  margin-right: 15px;
  flex: 6;
}

.pick-slip-row .print-quantity {
  flex: .5
}

.pick-slip-row .print-quantity p {
  font-size: 25px;
  font-weight: 600;
  float: right;
}


.pick-slip-header h3.ui.header {
  margin-left: 0px;
}

.pick-slip-header .ui.grid>.row>.column {
  margin-top: 15px;
}

.reprint {
  position: absolute;
  top : 200px;
  width: 330px;
  right: 40px;
  text-align: center;
}

.reprint b {
  font-size: 30px;
  color: red;
}

.create-pick-slip-table-container {
  position: relative;
}

.create-pick-slip-table-container .green {
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
}

.priority {
  position: absolute;
  top : 250px;
  width: 330px;
  right: 40px;
  text-align: center;
}

.priority b {
  font-size: 30px;
  color: blue;
}

.pick-slip-search-container {
  text-align: center;
  margin-top: 0px;
}

.pick-slip-search-container .ui.header {
    line-height: 30px;
}

.pick-slip-id-search {
  text-align: center;
  max-width: 570px;
  margin: auto;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 100px;
}

.pick-slip-id-search .ui.massive.input {
  font-size: 40px;
}

.bar-code-icon {
  text-align: center;
  margin-top: 65px;
  font-size: 100px;
}

.bar-code-icon h5 {
  text-align: center;
  margin-top: -30px !important;
  margin-bottom: 100px;
  font-size: 40px !important;
}

.box-selection-row {
  display: flex;
  flex-wrap:  wrap;
  justify-content: center;
}

.box-selection-row .segment {
  width: 200px;
  text-align: center !important;
  margin: 10px;
  margin-bottom: 20px;
}

.box-selection-row button {
  position: inline;
  background: transparent;
  border: none;
  padding: 0px;
  /* Detects if wheter or not the outline should appear on tab */
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.box-selection-row h3 {
  margin: auto !important;
  text-align: center !important;
  width: fit-content !important;
}

.box-selection-image {
  width: 100px;
  height: 100px;
  margin: auto;
}

.box-selected {
  background: #2ecc71  !important;
  color: white !important;
}
.box-selected h3 {
  
  color: white !important;
}